import { useNote } from 'contexts/NoteContext';
import Tippy from '@tippyjs/react';
import DeleteConfirmationModal from 'modals/DeleteConfirmationModal';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { BsPinAngle, BsPinAngleFill } from 'react-icons/bs';
import { deleteNoteType, pin } from 'services/noteType';
import styled from 'styled-components';
import { blue, green, red } from 'styles/colors';

import { AuthContext } from '@note/contexts/AuthContext';

import './tippy-notedesigner.css';

const NoteTypeListItem = ({ notetype, setSelectedNoteType, selectedNoteType, canDelete, hidePin = false }) => {
	const { user } = useContext(AuthContext);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const { noteTypes, setNoteTypes } = useNote();
	const isDemoAndNote = user?.is_demo && !(notetype.name === 'Demo Note' || notetype.name.startsWith('Auto AI Note'));

	const openDeleteModal = useCallback(() => setShowDeleteModal(true), []);
	const closeDeleteModal = useCallback(() => setShowDeleteModal(false), []);

	const remove = useCallback(
		async selectedNoteTypeId => {
			await deleteNoteType(selectedNoteTypeId);
			setNoteTypes(noteTypes.filter(noteType => noteType.id !== selectedNoteTypeId));
		},
		[noteTypes, setNoteTypes]
	);

	const isMyNote = noteType => {
		if (!user) return false;
		return noteType.user === user.id;
	};

	const isPinned = useMemo(() => {
		return notetype.is_pinned_by.includes(user.id);
	}, [user, notetype]);

	const toggleNoteType = async () => {
		setNoteTypes(
			noteTypes.map(nt => {
				if (nt.id === notetype.id) {
					if (isPinned) {
						return { ...nt, is_pinned_by: nt.is_pinned_by.filter(pinneduser => pinneduser !== user.id) };
					}
					return { ...nt, is_pinned_by: [...nt.is_pinned_by, user.id] };
				}
				return nt;
			})
		);
		await savePin();
	};

	const savePin = async () => {
		try {
			await pin(notetype.id);
		} catch (err) {
			console.error('🚀 ~ file: NoteTypeListItem.js ~ line 39 ~ savePin ~ error', err);
		}
	};

	const displayName = `${notetype.name}${notetype.is_tour ? '  🚀' : ''}`;

	// Define descriptions for special note types
	let description = null;
	if (notetype.name === 'Demo Note' && notetype.user === null) {
		description = "[select from lists]";
	} else if (notetype.name?.startsWith('Auto AI Note') && notetype.user === null) {
		description = "[text input]";
	}

	return (
		<Tippy placement="right-end" content={notetype.description} arrow={true} theme="tippy-nd">
			<div>
				<RadioItem font={isMyNote(notetype) ? 'bold' : 'normal'}
						   className={`new-note-radio-item ${isDemoAndNote ? 'disabled-tippy' : ''}`}>
					<input
						checked={parseInt(selectedNoteType) === notetype.id}
						value={notetype.id}
						name="notegroup"
						onChange={e => setSelectedNoteType(e.target.value)}
						type="radio"
						disabled={isDemoAndNote}
					/>
					{displayName}
					{description && (
						<span style={{ marginLeft: '0.5em', fontStyle: 'italic', fontSize: '0.7em', color: '#666' }}>
							{description}
						</span>
					)}

					{hidePin === false && (
						<PinContainer className="pin-container">
							{canDelete && <DeleteButton onClick={openDeleteModal}>Delete Permanently</DeleteButton>}
							<PinButton
								title={isPinned ? 'Unpin note-type' : 'Pin note-type'}
								onClick={toggleNoteType}
								ispinned={isPinned}
								disabled={isDemoAndNote}
							>
								{isPinned ? <BsPinAngle /> : <BsPinAngleFill />}
							</PinButton>
						</PinContainer>
					)}
				</RadioItem>
				<DescriptionCard>{notetype.description}</DescriptionCard>
				{showDeleteModal && (
					<DeleteConfirmationModal
						id={notetype.id}
						name={notetype.name}
						entityType="note type"
						deleteEntity={remove}
						show={showDeleteModal}
						handleClose={closeDeleteModal}
					/>
				)}
			</div>
		</Tippy>
	);
};

export default NoteTypeListItem;

const RadioItem = styled(Form.Label)`
	width: 100%;
	position: relative;
	cursor: pointer;
	display: block;
	padding: 10px 10px;
	margin-bottom: 7px;
	border: 1px solid #e5e5e5;
	border-radius: 5px;
	font-weight: ${props => props.font};
	input {
		margin-left: 0 !important;
		margin-right: 10px;
	}
`;

const PinContainer = styled.div`
	position: absolute;
	right: 7px;
	top: 7px;
`;

const DeleteButton = styled.button`
	border: none;
	background: ${red};
	color: white;
	padding: 3px 6px;
	border-radius: 5px;
	margin-right: 7px;
`;

const PinButton = styled.button`
	border: none;
	background: ${props => (props.ispinned ? green : blue)};
	color: white;
	padding: 3px 6px;
	border-radius: 5px;
`;

const DescriptionCard = styled.div`
	display: none;
	position: absolute;
	right: 100%;
	top: 0;
	width: 250px;
	background: white;
	color: black;
	border: 1px solid #e5e5e5;
	border-radius: 5px;
	padding: 10px;
	z-index: 100;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

const ListItemContainer = styled.div`
	position: relative;
	&:hover ${DescriptionCard} {
		display: block;
	}
`;
