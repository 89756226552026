import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Tabs, ListDashes, CursorClick } from '@phosphor-icons/react';
import styled from 'styled-components';
import { ThemeButton } from '@note/components/ThemeButton';

const DemoModal = () => {
  const [showModal, setShowModal] = useState(true);

  const FeatureItem = ({ icon: Icon, title, text }) => {
    return (
      <ActionItemContainer>
        <IconWrapper>
          <Icon size="3rem" weight="regular" />
        </IconWrapper>
        <Title>{title}</Title>
        <Text>{text}</Text>
      </ActionItemContainer>
    );
  };

  return (
    <StyledModal
      style={{ border: '2px solid grey' }}
      backdrop="static"
      scrollable
      centered
      size="lg"
      backdropClassName="backdrop-blur"
      show={showModal}
      onHide={() => setShowModal(false)}
    >
      <ModalBody>
        <h3 style={{ marginBottom: '1rem' }}>Welcome to the Demo!</h3>
        <div>
          <p>Here we offer two demonstration note-types, simplified to show just the basic features.</p>
          <p>We will start with our text-input "Auto AI Note", which is our simplest. You can also click on the green <b>+New Note</b> button at the top left to try our "Demo Note" which involves making selections from lists of content.</p>
        </div>

        <FeaturesContainer>
          <FeatureItem
            icon={Tabs}
            title="Organized Sections"
            text='Sections of the note are shown in "tabs" across the top of the screen.'
          />
          <FeatureItem
            icon={ListDashes}
            title="Subtab Navigation"
            text='Content is organized in "subtabs" down the left of the screen.'
          />
          <FeatureItem
            icon={CursorClick}
            title="Interactive Building"
            text="You can click on each tab and subtab, then click on specific phrases to see your note get built at the right."
          />
        </FeaturesContainer>

        <BottomText>
          <HighlightedSpan>When you are finished with this demo, click on the orange button</HighlightedSpan> at the top
          to access the fully-featured program where you can use different note formats,
          add and fully customize content, use all the AI functionality, and more.
        </BottomText>
      </ModalBody>

      <Modal.Footer>
        <ButtonContainer>
          <ThemeButton
            label="Start Demo"
            size="lg"
            color="blue"
            onClick={() => setShowModal(false)}
          />
        </ButtonContainer>
      </Modal.Footer>
    </StyledModal>
  );
};

export default DemoModal;

const ModalBody = styled(Modal.Body)`
  padding: 2rem;
  overflow: hidden !important;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledModal = styled(Modal)`
  & .modal-content {
    border: 2px solid gray;
  }
`;

const FeaturesContainer = styled.div`
  margin: 2rem 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  width: 100%;
`;

const ActionItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  padding: 0 1rem;
  height: 100%;
`;

const IconWrapper = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

const Title = styled.b`
  margin-bottom: 1rem;
`;

const Text = styled.div`
  text-align: center;
`;

const BottomText = styled.div`
  text-align: center;
  font-weight: bold;
`;

const HighlightedSpan = styled.span`
  background-color: #FFBC70;
  padding: 2px 4px;
  border-radius: 4px;
`;
