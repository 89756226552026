import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

import { ThemeButton } from '@note/components/ThemeButton';
import AISettings from '../layouts/note-selector/aiSettings/AISettings';

const AiSettingsModal = ({ show, handleClose, onCancel, onSubmit }) => {
	const [showScrollMessage, setShowScrollMessage] = useState(true);
	const modalBodyRef = useRef();

	useEffect(() => {
		if (show && modalBodyRef.current) {
			const handleScroll = () => {
				const { scrollTop, scrollHeight, clientHeight } = modalBodyRef.current;
				const isAtBottom = scrollTop + clientHeight >= scrollHeight;
				setShowScrollMessage(!isAtBottom);
			};

			const bodyElement = modalBodyRef.current;
			bodyElement.addEventListener('scroll', handleScroll);
			handleScroll();

			return () => bodyElement.removeEventListener('scroll', handleScroll);
		}
	}, [show]);

	const handleCancel = () => {
		onCancel();
		handleClose();
	};

	const handleAccept = () => {
		onSubmit();
	};

	const renderAiSettingsForm = () => {
		return (
			<div style={{ padding: '0 1rem' }}>
				<AISettings />
				<div style={{ marginTop: '1rem' }}>
					<b>Consent for using Note Designer's AI functions.</b>
					<p style={{ marginTop: 10 }}>
						I confirm that identifying PHI appears only in the Headings tab not in any other customized or text fields.
					</p>
				</div>
			</div>
		);
	};

	const renderShortForm = () => {
		return renderAiSettingsForm();
	};

	return (
		<StyledModal
			style={{ border: '2px solid grey', zIndex: '9000' }}
			show={show}
			onHide={() => handleClose(true)}
			backdrop="static"
			scrollable
			centered
			size="lg"
			backdropClassName="backdrop-blur"
			keyboard={false}
		>
			<Modal.Header>
				<Modal.Title>AI settings</Modal.Title>
			</Modal.Header>
			<Modal.Body ref={modalBodyRef}>{renderShortForm()}</Modal.Body>
			<Modal.Footer>
				<FooterContent>
					<ScrollMessage $show={showScrollMessage}>
						<BigArrow>↓</BigArrow>
						Scroll down for more
					</ScrollMessage>
					<ConsentButtons>
						<ThemeButton label={'Confirm and submit to AI'} size="lg" color="green" onClick={handleAccept} />

						<CancelButton>
							<ThemeButton label={'Close'} size="lg" color="red" onClick={handleCancel} />
						</CancelButton>
					</ConsentButtons>
				</FooterContent>
			</Modal.Footer>
		</StyledModal>
	);
};

export default AiSettingsModal;

const StyledModal = styled(Modal)`
	& .modal-content {
		border: 2px solid gray;
	}
`;

const CancelButton = styled('div')`
	margin-left: 1rem;
`;

const ConsentButtons = styled('div')`
	display: flex;
	flex-direction: row;
`;

const ScrollMessage = styled('div')`
	color: #666;
	font-style: italic;
	font-size: 1em;
	margin-right: auto;
	padding-left: 15px;
	display: flex;
	align-items: center;
	gap: 8px;
	opacity: ${props => props.$show ? 1 : 0};
	transition: opacity 0.2s ease;
	pointer-events: none;
`;

const FooterContent = styled('div')`
	width: 100%;
	display: flex;
	align-items: center;
`;

const BigArrow = styled('span')`
	display: inline-block;
	font-size: 2em;
`;
