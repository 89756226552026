import AILightbulbImage from 'assets/AI_light_bulb.png';
import InsightsModal from "@note/modals/InsightsModal";
import { isDemoState } from '@note/atoms';
import { useRecoilValue } from 'recoil';
import Tippy from '@tippyjs/react';
import { ThemeButton } from 'components/ThemeButton';
import { useNote } from 'contexts/NoteContext';
import ManageNoteTypeModal from 'layouts/note-type/manage-notetype/ManageNoteTypeModal';
import React, { useCallback, useState, useContext, useMemo } from 'react';
import { Navbar } from 'react-bootstrap';
import { FaPlus, FaTimesCircle } from 'react-icons/fa';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '@note/contexts/AuthContext';
import styled from 'styled-components';
import DesignNoteLogo from './DesignNoteLogo';
import NoteDesignerMenu from './NoteDesignerMenu';

import './MainAppNav.scss';

import NewNoteMainPageModal from '../../../note-type/NewNoteMainPageModal';
import NavMenu from '../../nav-menu/NavMenu';
import NavLinks from '../../NavLinks';
import { DesignNoteMessageModal } from '../DesignNoteMessageModal';
import { DesignNoteConfirm } from '../DesignNoteConfirm';

// Top nav bar
const MainAppNav = ({ isLoading, clearNote, toggleCustomizations, showCustomizations }) => {
	const navigate = useNavigate();
	const { isAdmin, user } = useContext(AuthContext);
	const { isCustomizable, activeNoteType, noteTypes } = useNote();
	const [searchParams] = useSearchParams();
	const [showDesignMessage, setShowDesignMessage] = useState(false);
	const [showDesignConfirm, setShowDesignConfirm] = useState(false);
	const [showNewNoteMainPageModal, setShowNewNoteMainPageModal] = useState(false);
	const [showManageNoteTypeModal, setShowManageNoteTypeModal] = useState(false);
	const [isEndingDemo, setIsEndingDemo] = useState(false);

	const openDesignMessage = useCallback(() => setShowDesignMessage(true), []);
	const closeDesignMessage = useCallback(() => setShowDesignMessage(false), []);

	const openDesignConfirm = useCallback(() => setShowDesignConfirm(true), []);
	const closeDesignConfirm = useCallback(() => setShowDesignConfirm(false), []);
	const openNewNoteMainPageModal = useCallback((endingDemo = false) => {
        setIsEndingDemo(endingDemo);
        setShowNewNoteMainPageModal(true);
    }, []);
	const endThisDemo = useCallback((endingDemo = false) => {
    if (user?.is_demo) {
      window.open('https://notedesigner.com/pricing/', '_blank');
    } else {
      setIsEndingDemo(endingDemo);
      setShowNewNoteMainPageModal(true);
    }
  }, [user]);
	const closeNewNoteMainPageModal = useCallback(() => {
        setIsEndingDemo(false);
        setShowNewNoteMainPageModal(false);
    }, []);
	const openManageNoteTypeModal = useCallback(() => setShowManageNoteTypeModal(true), []);
	const closeManageNoteTypeModal = useCallback(() => setShowManageNoteTypeModal(false), []);

	const isAINote = activeNoteType?.is_ai_note || activeNoteType?.is_ai_plus_note;
	const isDesignSpaceUnavailable = !isAdmin && isAINote && user?.site?.id === 1;
	const isDemo = useRecoilValue(isDemoState);
	const [showInsightsModal, setShowInsightsModal] = useState(false);
	const openInsightsModal = useCallback(() => setShowInsightsModal(true), []);
	const closeInsightsModal = useCallback(() => setShowInsightsModal(false), []);

	const handleCustomizeClick = useCallback(() => {
		const id = searchParams.get('id');
		if (activeNoteType?.is_tour) {
			navigate('/design-note?id=' + id);
			return;
		}

		if (!isCustomizable) {
			openDesignMessage();

			return;
		}
		openDesignConfirm();
	}, [activeNoteType?.is_tour, isCustomizable, navigate, openDesignConfirm, openDesignMessage, searchParams]);

	const activeNoteTypeName = useMemo(() => {
		const id = searchParams.get('id');
		if (id && noteTypes && noteTypes.length > 0) {
			const notetype = noteTypes.find(nt => nt.id === parseInt(id));
			return notetype?.name ? notetype.name : 'Tab';
		}
		return '';
	}, [noteTypes, searchParams]);

	return (
		<NavigationBar className="px-3 shadow" collapseOnSelect expand="lg" bg="dark" variant="dark">
			<NoteDesignerMenu />

			{!isLoading && (
				<>
					<HeaderMenu>
						<ButtonContainer>
							<ThemeButton size="sm" onClick={() => openNewNoteMainPageModal(false)} label="New Note" icon={FaPlus} />
							<Tippy content="Not available in demo" disabled={!isDemo}>
								<div style={{ display: 'inline-flex', pointerEvents: 'auto' }}>
									<ThemeButton
										size="sm"
										onClick={handleCustomizeClick}
										color={!isDesignSpaceUnavailable ? 'green' : 'grey'}
										label="Customize This Note"
										icon={DesignNoteLogo}
										disabled={isDemo ? true : isDesignSpaceUnavailable}
										tourAnchor="tour-design"
										inverted
									/>
								</div>
							</Tippy>
							<Tippy content="Insights: Ask AI" placement="bottom">
								<LightbulbButton onClick={openInsightsModal}>
									<img
										src={AILightbulbImage}
										alt="Insights: Ask AI"
									/>
								</LightbulbButton>
							</Tippy>
							{isAdmin && <p className="animated-text-component">ADMIN!!</p>}
							{isDesignSpaceUnavailable && <HeaderText>Design space is unavailable in AI Notes</HeaderText>}
						</ButtonContainer>
						{isDemo && (
							<DemoButtonWrapper>
								<ThemeButton
									size="lg"
									onClick={() => endThisDemo(true)}
									label="Exit the Demo to Unlock all the Features"
									icon={FaTimesCircle}
									color="orange"
								/>
							</DemoButtonWrapper>
						)}
						<div style={{ display: 'flex' }}>
							<div className="main-nav-navlinks">
								<NavLinks noteTypeName={activeNoteTypeName} />
							</div>
							<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
								<NavMenu
									showCustomizations={showCustomizations}
									toggleCustomizations={toggleCustomizations}
									noteTypeName={activeNoteTypeName}
								/>
							</div>
						</div>
					</HeaderMenu>
					<DesignNoteMessageModal show={showDesignMessage} handleClose={closeDesignMessage} />
					<DesignNoteConfirm show={showDesignConfirm} handleClose={closeDesignConfirm} />
					<NewNoteMainPageModal
						clearNote={clearNote}
						openManageNoteTypeModal={openManageNoteTypeModal}
						show={showNewNoteMainPageModal}
						handleClose={closeNewNoteMainPageModal}
						isEndingDemo={isEndingDemo}
					/>
					<ManageNoteTypeModal show={showManageNoteTypeModal} handleClose={closeManageNoteTypeModal} />
					<InsightsModal
					show={showInsightsModal}
					handleClose={closeInsightsModal}
					/>
				</>
			)}
		</NavigationBar>
	);
};

export default React.memo(MainAppNav);

const NavigationBar = styled(Navbar)`
	width: 100%;
`;

const ButtonContainer = styled.div`
	display: flex;
	gap: 5px;
	max-height: 55px;
`;

const HeaderMenu = styled.div`
	display: flex;
	@media (min-width: 992px) {
		width: 100%;
		justify-content: space-between;
	}
`;

const HeaderText = styled.span`
	margin-left: 10px;
	color: grey;
`;

const DemoButtonWrapper = styled.div`
    display: flex;
    align-items: center;

    button {
        min-width: 380px;
        height: 100%;
    }
`;

const LightbulbButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  height: 38px;
  width: 38px;
  opacity: ${props => props.disabled ? 0.5 : 1};
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    outline: none;
  }

  img {
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
`;
