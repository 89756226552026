import { useRecoilValue } from 'recoil';
import { isDemoState } from '@note/atoms';
import { useNote } from 'contexts/NoteContext';
import React, { useContext, useMemo } from 'react';
import { AuthContext } from '@note/contexts/AuthContext';
import NoteTypeListItem from '@note/layouts/note-type/note-type-list/NoteTypeListItem';
import { green } from 'styles/colors';
import NoteTypeListGroup from './NoteTypeListGroup';

const WelcomeMessage = () => (
	<div className="alert alert-warning mb-3">
		<h5 className="alert-heading">Welcome to Note Designer! 🎉</h5>
		<p className="mb-0" style={{ textAlign: 'left' }}>
			Welcome aboard! To help you get started, we strongly recommend you try our demo note template to learn the
			basic features of the program :
		</p>
		<ul style={{ textAlign: 'left', margin: '10px 0 0 0', paddingLeft: '20px' }}>
			<li>Click the buttons just below here to choose either the Demo Note or the Auto AI note, then</li>
			<li>Click the green button at the bottom to start the note.</li>
		</ul>
	</div>
);

const NoteTypeList = ({
						  selectedNoteType,
						  setSelectedNoteType,
						  isArchivedVisible = false,
						  isManage = false,
						  fromTermsAcceptance,
						  isEndingDemo = false
					  }) => {
	const { noteTypes, noteTypeGroups } = useNote();
	const { user } = useContext(AuthContext);
	const demoNote = noteTypes?.find(note => note.name === 'Demo Note' && note.user === null);
	const autoAiNote = noteTypes?.find(note => note.name?.startsWith('Auto AI Note') && note.user === null)
	const isDemo = useRecoilValue(isDemoState);

	// Filtering the note types to get the ones created by the current user, that are not pinned or archived
	const myNoteTypes = useMemo(() => {
		if (noteTypes && noteTypes.length > 0) {
			return noteTypes.filter(nt => nt.user === user?.id && !nt.is_pinned_by.includes(user?.id) && !nt.is_archived);
		}
		return [];
	}, [noteTypes, user]);

	// Filtering the note types to get those not created by the user, not pinned, not part of the tour, and not archived
	const otherNoteTypes = useMemo(() => {
		if (noteTypes && noteTypes.length > 0) {
			return noteTypes.filter(
				nt => nt.user !== user?.id &&
					nt.is_tour === false &&
					nt !== demoNote &&
					nt !== autoAiNote &&
					!nt.is_pinned_by.includes(user?.id) &&
					!nt.is_archived
			);
		}
		return [];
	}, [noteTypes, user, demoNote, autoAiNote]);

	// Filtering the note types to get those pinned by the current user and not archived
	const pinnedNoteTypes = useMemo(() => {
		if (noteTypes && noteTypes.length > 0) {
			return noteTypes.filter(nt => nt.is_pinned_by.includes(user?.id) && !nt.is_archived);
		}
		return [];
	}, [noteTypes, user]);

	// Filtering the note types to get the ones that are archived
	const archivedNoteTypes = useMemo(() => {
		if (noteTypes && noteTypes.length > 0) {
			return noteTypes.filter(nt => nt.is_archived);
		}
		return [];
	}, [noteTypes]);

	return (
		<>
			<div style={{ textAlign: 'center'}}>
				{!isEndingDemo && (fromTermsAcceptance || isDemo || user?.is_demo) ? (
					<>
						<WelcomeMessage/>
						{demoNote && autoAiNote && (
							<div style={{ padding: '1rem', textAlign: 'left' }}>
								<NoteTypeListItem
									notetype={autoAiNote}
									setSelectedNoteType={setSelectedNoteType}
									selectedNoteType={selectedNoteType}
									hidePin={true}
								/>
								<NoteTypeListItem
									notetype={demoNote}
									setSelectedNoteType={setSelectedNoteType}
									selectedNoteType={selectedNoteType}
									hidePin={true}
								/>
							</div>
						)}
						<hr/>
					</>
				) : (
					<i style={{ color: green, padding: 'unset' }}>AI Rewrite is now available in every note-type!</i>
				)}
			</div>
			<NoteTypeListGroup
				isManage={isManage}
				label="Pinned note-types"
				message="Your pinned note-types will show here"
				groupedNoteTypes={pinnedNoteTypes}
				setSelectedNoteType={setSelectedNoteType}
				selectedNoteType={selectedNoteType}
			/>
			<NoteTypeListGroup
				isManage={isManage}
				label="Default note-types"
				groupedNoteTypes={otherNoteTypes}
				message="No note-types found"
				setSelectedNoteType={setSelectedNoteType}
				selectedNoteType={selectedNoteType}
				noteTypeGroups={noteTypeGroups}
			/>
			<NoteTypeListGroup
				isManage={isManage}
				label="My note-types"
				message="No note-types found"
				groupedNoteTypes={myNoteTypes}
				setSelectedNoteType={setSelectedNoteType}
				selectedNoteType={selectedNoteType}
			/>
			{isArchivedVisible && (
				<NoteTypeListGroup
					label="Archived note-types"
					message="No note-types found"
					groupedNoteTypes={archivedNoteTypes}
					setSelectedNoteType={setSelectedNoteType}
					selectedNoteType={selectedNoteType}
					canDelete={true}
				/>
			)}
		</>
	);
};

export default NoteTypeList;
