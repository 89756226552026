import html2pdf from 'html2pdf.js/dist/html2pdf';

// This function exports the content of a specified HTML element to a PDF file.
export const exportPdf = async ({
	id = 'note',
	fontSize,
	fontStyle,
	setIframeURL,
	setShowExport,
	isNoteOutput = false,
}) => {
	// Helper function to calculate margin based on font size
	const marginMultiplier = () => {
		let value;

		switch (parseInt(fontSize)) {
			case 12:
				value = 4.02;
				break;
			case 14:
				value = 2.45;
				break;
			case 16:
				value = 2.07;
				break;
			case 18:
				value = 2.57;
				break;
			case 20:
				value = 1.92;
				break;
			default:
				value = 1;
				break;
		}

		return value;
	};

	const calculateMargin = () => fontSize * marginMultiplier(); // Calculate margin based on font size and multiplier

	// PDF generation options
	const opt = {
		margin: [calculateMargin(), 15], // Margins for the PDF
		filename: 'myfile.pdf', // Default filename
		image: { type: 'jpeg', quality: 0.98 }, // Image settings
		html2canvas: { scale: 2, bottom: 10 }, // Canvas settings
		jsPDF: { unit: 'pt', orientation: 'portrait', floatPrecision: 'smart' }, // PDF settings
		pagebreak: { mode: ['css', 'legacy'] }, // Page break settings
	};

	// Clone the content of the specified element
	const exportDoc = document.getElementById(id);
	const clone = exportDoc.cloneNode(true);
	clone.classList.add('pdf-content');

	// Remove unnecessary elements from the cloned content
	const quillToolbars = clone.querySelectorAll('.ql-toolbar');
	quillToolbars.forEach(toolbar => toolbar.remove());

	const quillContainers = clone.querySelectorAll('.ql-container');
	quillContainers.forEach(container => {
		const editorContent = container?.querySelector('.ql-editor').innerHTML;
		container.parentNode.innerHTML = editorContent;
	});

	const tips = clone.querySelectorAll('.tips');
	tips.forEach(tip => tip.remove());

	const introComments = clone.querySelectorAll('[class~="intro-comments"]');
	introComments.forEach(comment => (comment.innerText ? comment : comment.remove()));

	const ImageCations = clone.querySelectorAll('[class~="image-caption"]');
	ImageCations.forEach(comment => (comment.innerText ? comment : comment.remove()));

	const signatureButton = clone.querySelectorAll('[class~="signature-button"]');
	signatureButton.forEach(btn => btn.remove());

	// Set the font style for the cloned content
	let font = 'Times New Roman';
	if (fontStyle === 'Arial') {
		font = 'Arial, sans-serif';
	} else if (fontStyle === 'DejaVu') {
		font = 'dejavu';
	} else if (fontStyle === 'Courier') {
		font = 'courier';
	}
	clone.style.fontFamily = font;

	// Apply font size and remove empty elements
	Object.values(clone.childNodes).forEach(el => {
		// Check if element has images before removing it
		const hasImages = el.querySelector && el.querySelector('img');
		if (!el.textContent && el.textContent.length === 0 && !hasImages) {
			clone?.removeChild(el);
		}
		if (el.nodeName !== 'H3') {
			el.style.fontSize = fontSize + 'px';
		}
	});

	// Replace empty paragraph tags with line breaks
	clone.innerHTML = clone.innerHTML.replace(/<p><br\s?\/?><\/p>/g, '<br />');

	// Additional content adjustments for non-note outputs
	if (!isNoteOutput) {
		const patternToRemove = /<\/p><br\s?\/?>/g;
		clone.innerHTML = clone.innerHTML.replace(patternToRemove, '</p>');
	}

	// Generate the PDF and convert it to a Blob
	const output = await html2pdf().set(opt).from(clone).output('blob');
	const blob = new Blob([output], { type: 'application/pdf' });

	// Handle PDF viewing differently based on the browser
	if (
		navigator.userAgent.indexOf('Safari') !== -1 &&
		navigator.userAgent.indexOf('Chrome') === -1 &&
		navigator.userAgent.indexOf('Chromium') === -1
	) {
		const newWin = window.open(URL.createObjectURL(blob));
		if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
			alert('You have disabled popups. To make sure export works, enable popups in the browser');
		} else {
			newWin.focus();
		}
	} else {
		setIframeURL(URL.createObjectURL(blob)); // Set the generated PDF URL to be displayed in an iframe
		setShowExport(true);
	}
};
