import { Draggable } from '@hello-pangea/dnd';
import Tippy from '@tippyjs/react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { usePreference } from 'contexts/PreferenceContext';
import { Nav } from 'react-bootstrap';
import { FaFileExport, FaPencilAlt } from 'react-icons/fa';
import { useRecoilValue } from 'recoil';
import { useNote } from 'contexts/NoteContext';
import styled from 'styled-components';
import { blue, purple } from 'styles/colors';
import { BsFillDiamondFill } from 'react-icons/bs';

import { isDesignPageState, sectionsState, designTourState, isDemoState } from '@note/atoms';
import { AuthContext } from '@note/contexts/AuthContext';

import SectionList from './SectionList';

const SubTabNavItem = ({
	subTab,
	index,
	handleAdd,
	activeSubTab,
	setActiveSubTab,
	activeSection,
	setActiveSection,
}) => {
	const { activeNoteType } = useNote();
	const isDesignPage = useRecoilValue(isDesignPageState);
	const sections = useRecoilValue(sectionsState);
	const isDesignTourState = useRecoilValue(designTourState);
	const { setShowCloneModal } = useContext(AuthContext);
	const [filteredSections, setFilteredSections] = useState([]);
	const { globalPreferences } = usePreference();
	const isDemo = useRecoilValue(isDemoState);

	// Filters sections based on the selected sub-tab
	useEffect(() => {
		if (sections) setFilteredSections(sections.filter(section => section.subtab === subTab.id));
	}, [subTab, sections]);

	// Handles click on a sub-tab to set it as active
	const handleSubTabClick = useCallback(() => {
		setActiveSubTab(subTab);
		setActiveSection(filteredSections[0]);
	}, [filteredSections, setActiveSection, setActiveSubTab, subTab]);

	// Handles cloning of a sub-tab
	const handleSubTabClone = tab => {
		!isDesignTourState && setShowCloneModal({ show: true, id: tab.id, isSubTab: true });
	};

	// Handles editing of a sub-tab
	const handleSubTabEdit = tab => {
		!isDesignTourState && handleAdd(subTab);
	};

	// Determines whether the menu should be hidden based on user preferences
	const hideMenu = globalPreferences.CollapseSubtabs === 'On';

	// Checks if the current sub-tab is active
	const isActive = subTab.id === activeSubTab.id;

	return (
		<>
			{isDesignPage && (
				<Draggable draggableId={subTab.id.toString()} index={index} isDragDisabled={!isDesignPage}>
					{(provided, snapshot) => (
						<>
							<NavItem
								key={index}
								title={subTab.name}
								ref={provided.innerRef}
								{...provided.draggableProps}
								{...provided.dragHandleProps}
								className={
									// Applies specific classes based on the sub-tab name for custom styling
									subTab.name.toLowerCase() === 'reported affective state'
										? 'ras-subtab'
										: subTab.name.toLowerCase() === 'new subtab'
										? 'new-subtab'
										: subTab.name.toLowerCase() === 'themes discussed'
										? 'themes-subtab'
										: ''
								}
							>
								<NavLink eventKey={subTab.id} onClick={handleSubTabClick}>
									<BsFillDiamondFill className="diamond-icon" />
									{subTab.name}

									<div>
										<Tippy content="Click here if you want to clone this subtab and duplicate it into another location">
											<IconWrapper style={{ marginRight: '5px' }} onClick={() => handleSubTabClone(subTab)}>
												<FaFileExport />
											</IconWrapper>
										</Tippy>
										{!activeNoteType.is_tour && (
											<Tippy content="Edit">
												<IconWrapper onClick={() => handleSubTabEdit()}>
													<FaPencilAlt />
												</IconWrapper>
											</Tippy>
										)}
									</div>
								</NavLink>
								{!hideMenu && (
									<SectionList
										subTab={subTab}
										filteredSections={filteredSections}
										setFilteredSections={setFilteredSections}
										setActiveSubTab={setActiveSubTab}
										activeSection={activeSection}
										setActiveSection={setActiveSection}
									/>
								)}
							</NavItem>
						</>
					)}
				</Draggable>
			)}
			{!isDesignPage && (
				<>
					<NavItem key={index} title={subTab.name}>
						<NavLink
							eventKey={subTab.id}
							onClick={handleSubTabClick}
							className={subTab.name.toLowerCase() === 'themes discussed' ? 'subtab-tour' : ''}
						>
							<BsFillDiamondFill className="diamond-icon" />
							{subTab.name}
							{!(activeNoteType?.is_ai_note || activeNoteType?.is_ai_plus_note) && !isDemo && (
								<Tippy content="Click here if you want to clone this subtab and duplicate it into another location">
									<IconWrapper
										style={{ marginRight: '15px', marginTop: '4px' }}
										onClick={() => handleSubTabClone(subTab)}
									>
										<FaFileExport />
									</IconWrapper>
								</Tippy>
							)}
						</NavLink>
						{(!hideMenu || (isActive && hideMenu)) && (
							<SectionList
								subTab={subTab}
								filteredSections={filteredSections}
								setFilteredSections={setFilteredSections}
								setActiveSubTab={setActiveSubTab}
								activeSection={activeSection}
								setActiveSection={setActiveSection}
							/>
						)}
					</NavItem>
				</>
			)}
		</>
	);
};

export default SubTabNavItem;

const NavItem = styled(Nav.Item)`
	height: auto !important;
	& > a.active {
		background: ${purple} !important;
	}
`;

const NavLink = styled(Nav.Link)`
	position: relative;
	color: black;
	cursor: pointer;
	font-weight: 600;
	font-size: 15px;
	display: flex;
	align-items: center;
	gap: 6px;
	
	svg.diamond-icon {
		color: ${blue};
		font-size: 12px;
		flex-shrink: 0;
	}
	
	&:hover {
		color: black;
		background: #f9f9f9;
		& > div {
			display: flex;
			position: absolute;
			top: 2px;
			right: -10px;
			background-color: white;
			border: 2px solid lightgray;
			border-radius: 1rem;
			padding: 4px;
			&:first-child {
				margin-right: 1rem;
			}
			& > div {
				display: flex;
			}
		}
	}
`;

const IconWrapper = styled.div`
	background: white;
	color: gray;
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	cursor: pointer;
	display: none;
	&:hover {
		border: 1px solid ${blue};
		color: ${blue};
	}
`;
