import AILightbulbImage from 'assets/AI_light_bulb.png';
import React, {useState} from 'react';
import {Button, Form, Modal, Spinner} from 'react-bootstrap';
import styled from 'styled-components';
import {toast} from 'react-toastify';
import {getPhi4ModifiedNote} from 'services/phi4AiEdit';
import {FaCheck, FaRegCopy} from 'react-icons/fa';
import DOMPurify from 'dompurify';

const SYSTEM_PROMPT = `<|im_start|>system<|im_sep|>
First, analyze the question submitted to determine if it is about psychology or a related field (e.g., psychiatry, medicine, mental health)

DO answer the submitted question if it is about psychology.

DO NOT provide any answer that is not related to psychology: instead say "I cannot answer questions that are unrelated to psychology, sorry." and then nothing else. Just say "Goodbye" and STOP. Do not add anything else.
<|im_end|>`;

const InsightsModal = ({ show, handleClose }) => {
  const [question, setQuestion] = useState('');
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [copyFeedback, setCopyFeedback] = useState(false);

  const handleCopy = async () => {
    try {
      const html = DOMPurify.sanitize(
        response.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>'),
        {ALLOWED_TAGS: ['strong', 'br']}
      ).replace(/\n/g, '<br>');
      const plain = response.replace(/\*\*/g, '');

      await navigator.clipboard.write([
        new ClipboardItem({
          'text/html': new Blob([html], { type: 'text/html' }),
          'text/plain': new Blob([plain], { type: 'text/plain' })
        })
      ]);

      toast.success('Copied formatted text!');
      setCopyFeedback(true);
      setTimeout(() => setCopyFeedback(false), 2000);
    } catch {
      const textArea = document.createElement('textarea');
      textArea.value = response.replace(/\*\*/g, '');
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      toast.success('Copied text!');
      setCopyFeedback(true);
      setTimeout(() => setCopyFeedback(false), 2000);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!question.trim()) return;

    setIsLoading(true);
    try {
      const result = await getPhi4ModifiedNote(
        `${SYSTEM_PROMPT}\n<|im_start|>user<|im_sep|>\n${question}<|im_end|>`
      );
      setResponse(result?.answer || result?.response || 'Sorry, there was an error processing your request.');
    } catch {
      toast.error('Error connecting to AI service');
      setResponse('Sorry, there was an error processing your request.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setQuestion('');
    setResponse('');
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title className="d-flex align-items-center gap-2">
          Insights: Ask AI
          <BulbIcon src={AILightbulbImage} alt="AI Insights" />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Your question</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              placeholder="e.g., suggest a few interventions for group therapy for addictions; provide an overview of Acceptance & Commitment Therapy; What is Object-Relations theory?; What is POTS?"
              disabled={isLoading}
            />
          </Form.Group>

          <ButtonContainer>
            <Button variant="primary" type="submit" disabled={!question.trim() || isLoading}>
              {isLoading ? (
                <>
                  <Spinner as="span" animation="border" size="sm" className="me-2" />
                  Processing...
                </>
              ) : 'Submit question'}
            </Button>
            <Button variant="outline-secondary" onClick={handleReset} disabled={isLoading || (!question && !response)}>
              Clear
            </Button>
          </ButtonContainer>
        </Form>

        {response && (
          <ResponseContainer>
            <ResponseHeader>
              <h5>Response:</h5>
              <CopyButton
                variant="link"
                onClick={handleCopy}
                $copied={copyFeedback}
                aria-label={copyFeedback ? "Copied" : "Copy response"}
              >
                {copyFeedback ? (
                  <span className="d-flex align-items-center gap-1">
                    <FaCheck size={14} />
                    <span className="text-success small">Copied!</span>
                  </span>
                ) : (
                  <span className="d-flex align-items-center gap-1" style={{ textDecoration: 'none' }}>
                    <FaRegCopy size={14} />
                    <span className="small">Copy</span>
                  </span>
                )}
              </CopyButton>
            </ResponseHeader>

            <Response>
              {response.split('\n\n').map((paragraph, pIndex) => (
                <div key={pIndex} style={{ marginBottom: '1rem' }}>
                  {paragraph.split('\n').map((line, lIndex) => (
                    <div key={lIndex} style={{ marginBottom: '0.5rem', whiteSpace: 'pre-wrap' }}>
                      {line.split(/(\*\*.*?\*\*)/g).map((part, partIndex) =>
                        part.startsWith('**') && part.endsWith('**') ? (
                          <strong key={partIndex}>{part.slice(2, -2)}</strong>
                        ) : part
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </Response>
          </ResponseContainer>
        )}
      </Modal.Body>

      <Modal.Footer className="flex-column align-items-start">
        <Disclaimer>
          <b>Disclaimer :</b> AI-generated responses may contain inaccuracies. It is your responsibility to verify
          information as needed. This content is provided as-is, without guarantees of accuracy,
          and should not be a substitute for professional advice. We assume no responsibility for
          any consequences resulting from its use.
        </Disclaimer>
        <div className="w-100 text-end">
          <Button variant="secondary" onClick={handleClose}>Close</Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(InsightsModal);

const Disclaimer = styled.div`
  background-color: #fff3cd;
  border-left: 4px solid #ffc107;
  padding: 12px;
  border-radius: 4px;
  font-size: 0.9rem;
  color: #856404;
  margin: 20px 0;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;

const ResponseContainer = styled.div`
  margin-top: 20px;
  border-top: 1px solid #dee2e6;
  padding-top: 20px;
`;

const Response = styled.div`
  white-space: pre-wrap;
  background-color: #fff;
  border: 1px solid #dee2e6;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
`;

const BulbIcon = styled.img`
  width: 30px;
  height: 30px;
  object-fit: contain;
`;

const ResponseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const CopyButton = styled(Button)`
  padding: 2px 6px;
  color: ${props => props.$copied ? 'green' : '#6c757d'} !important;
  text-decoration: none !important;
  transition: all 0.2s ease;

  &:hover {
    color: ${props => props.$copied ? 'green' : '#495057'} !important;
    background-color: #f8f9fa;
    text-decoration: none !important;
  }

  svg {
    transition: transform 0.2s ease;
    transform: ${props => props.$copied ? 'scale(1.2)' : 'scale(1)'};
  }

  span {
    text-decoration: none !important;
  }
`;
