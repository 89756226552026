import { useNote } from 'contexts/NoteContext';
import { usePreference } from 'contexts/PreferenceContext';
import React, {useCallback, useEffect, useState} from 'react';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

import RadioInput from '../RadioInput';

// Options for the prompt builder
const promptOptionsList = {
	noteStyle: {
		name: 'noteStyle',
		label: 'Note style',
		values: [
			{
				key: 'Use the formatting from the input note',
				text: "Importantly, your response MUST use exactly the same section {title} headings {/title} as indicated with {title}{/title} tags in the input.\n\nDo not include name, date, or other headings at the top of the note, but do keep the **headings**, each on its own line and using ** tags instead of {title} tags.",
			},
			{
				key: 'No headings/formatting',
				text: "Importantly, do not use any headings in the note.",
			},
			{
				key: 'SOAP',
				text: "Importantly, please make sure your response uses a SOAP format, with the only section headings being Subjective, Objective, Assessment, and Plan, with each heading indicated by including **bold** tags.\n\nDo not include name, date, or other headings at the top of the note, but do keep the ** headings **, each on its own line and using ** tags.",
			},
			{
				key: 'BIRP',
				text: "Importantly, please make sure your response uses a BIRP format, with the only section headings being Behavior, Intervention, Response, and Plan, with each heading indicated by including **bold** tags.\n\nDo not include name, date, or other headings at the top of the note, but do keep the ** headings **, each on its own line and using ** tags.",
			},
			{
				key: 'DAP',
				text: "Importantly, please make sure your response uses a DAP format, with the only section headings being Data, Assessment, and Plan, with each heading indicated by including **bold** tags.\n\nDo not include name, date, or other headings at the top of the note, but do keep the ** headings **, each on its own line and using ** tags.",
			},
			{
				key: 'GIRP',
				text: "Importantly, please make sure your response uses a GIRP format, with the only section headings being Goals, Intervention, Response, and Plan, with each heading indicated by including **bold** tags.\n\nDo not include name, date, or other headings at the top of the note, but do keep the ** headings **, each on its own line and using ** tags.",
			},
			{
				key: 'SIRP',
				text: "Importantly, please make sure your response uses a SIRP format, with the only section headings being Situation, Intervention, Response, and Plan, with each heading indicated by including **bold** tags.\n\nDo not include name, date, or other headings at the top of the note, but do keep the ** headings **, each on its own line and using ** tags.",
			},
			{
				key: 'PIE',
				text: "Importantly, please make sure your response uses a PIE format, with the only section headings being Problem, Intervention, and Evaluation, with each heading indicated by including **bold** tags.\n\nDo not include name, date, or other headings at the top of the note, but do keep the ** headings **, each on its own line and using ** tags.",
			},
			{
				key: 'Couples Therapy',
				text: "Importantly, please make sure your response is a couples therapy note with each heading indicated by including **bold** tags.\n\nDo not include name, date, or other headings at the top of the note, but do keep the ** headings **, each on its own line and using ** tags.",
			},
			{
				key: 'Family Therapy',
				text: "Importantly, please make sure your response is a family therapy note with each heading indicated by including **bold** tags.\n\nDo not include name, date, or other headings at the top of the note, but do keep the ** headings **, each on its own line and using ** tags.",
			},
			{
				key: 'Group Therapy',
				text: "Importantly, please make sure your response is a group therapy note with each heading indicated by including **bold** tags.\n\nDo not include name, date, or other headings at the top of the note, but do keep the ** headings **, each on its own line and using ** tags.",
			},
		],
	},
	terminology: {
		name: 'terminology',
		label: 'Terminology',
		values: [
			{
				key: 'Use the terminology from the input note',
				text: 'used in the input to refer to the person receiving the treatment (for example: client/patient/student/person/youth/child/couple/Veteran/service member).',
			},
			{
				key: 'client',
				text: '"client" instead of "patient" or any similar term.',
			},
			{
				key: 'patient',
				text: '"patient" instead of "client" or any similar term.',
			},
			{
				key: 'student',
				text: '"student" instead of "patient", "client" or any similar term.',
			},
			{
				key: 'individual',
				text: '"individual" instead of "patient", "client" or any similar term.',
			},
			{
				key: 'couple',
				text: '"couple" to refer to the person receiving the treatment.',
			},
			{
				key: 'member',
				text: '"member" instead of "patient", "client" or any similar term.',
			},
			{
				key: 'Veteran',
				text: '"Veteran" instead of "patient", "client" or any similar term.\'',
			},
			{
				key: 'service member',
				text: '"service member" instead of "patient", "client" or any similar term.',
			},
		],
	},
	length: {
		name: 'length',
		label: 'Length',
		values: [
			{
				key: 'comprehensive',
				text: 'relatively long and comprehensive.',
			},
			{
				key: 'more concise',
				text: 'relatively short and concise.',
			},
		],
	},
	pronouns: {
		name: 'pronouns',
		label: 'Pronouns',
		values: [
			{
				key: 'they / them',
				text: 'use they/them/their pronouns to refer to the patient,',
			},
			{
				key: 'she / her',
				text: 'use she/her/hers pronouns to refer to the female patient,',
			},
			{
				key: 'he / him',
				text: 'use he/him/his pronouns to refer to the male patient,',
			},
		],
	},
	pronounUse: {
		name: 'pronounUse',
		label: 'Pronoun use',
		values: [
			{
				key: 'frequent',
				text: 'frequently',
			},
			{
				key: 'occasional',
				text: 'occasionally',
			},
		],
	},
	therapistPronouns: {
		name: 'therapistPronouns',
		label: 'Therapist pronouns',
		values: [
			{
				key: 'third person',
				text: 'DO NOT',
			},
			{
				key: 'first person (I, me)',
				text: 'DO',
			},
		],
	},
	elaboration: {
		name: 'elaboration',
		label: 'Elaboration',
	},
	formatoptions: {
		name: 'formatoptions',
		label: 'Note format',
		values: [
			{
				key: 'narrative',
				text: 'Use the form of a narrative',
			},
			{
				key: 'point form',
				text: 'Use the form of bullet-points',
			},
		],
	},
	language: {
		name: 'language',
		label: 'Language',
		values: [
			{
				key: 'English',
				text: 'English',
			},
			{
				key: 'French',
				text: 'French',
			},
			{
				key: 'Spanish',
				text: 'Spanish',
			},
			{
				key: 'Portuguese',
				text: 'Portuguese',
			},
			{
				key: 'German',
				text: 'German',
			},
			{
				key: 'Italian',
				text: 'Italian',
			},
		],
	},
};

export const GlobalAiPromtBuilder = () => {
	const { setGlobalAIPrompt } = useNote();
	const { multiLevelPreferences } = usePreference();

	// State to manage the selected prompt options
	const [promptOptions, setPromptOptions] = useState({
		noteStyle: {
			value: "Importantly, your response MUST use exactly the same section {title} headings {/title} as indicated with {title}{/title} tags in the input.\n\nDo not include name, date, or other headings at the top of the note, but do keep the **headings**, each on its own line and using ** tags instead of {title} tags.",
		},
		terminology: { value: 'used in the input to refer to the person receiving the treatment (for example: client/patient/student/person/youth/child/couple/Veteran/service member).' },
		formatoptions: { value: 'Use the form of a narrative' },
		pronouns: { value: 'use they/them/their pronouns to refer to the patient,' },
		pronounUse: { value: 'frequently' },
		therapistPronouns: {value: 'DO NOT' },
		elaboration: { value: 0.5 },
		length: { value: 'relatively long and comprehensive.' },
		language: { value: 'English' },
	});

	// Handle changes to the prompt options
	const handleChange = useCallback((param, value) => {
		setPromptOptions(prevOptions => ({
			...prevOptions,
			[param]: { value },
		}));
	}, []);

	// Update the filled prompt whenever any of the selected values change
	useEffect(() => {
		// Extract all option values
		const {
			noteStyle: { value: noteStyleInstruction },
			terminology: { value: terminologyValue },
			pronouns: { value: pronounValue },
			pronounUse: { value: pronounUseValue },
			therapistPronouns: { value: therapistPronounsValue },
			length: { value: lengthValue },
			formatoptions: { value: formatValue }
		} = promptOptions;

		// Build the system prompt
		const systemPrompt = `<|im_start|>system<|im_sep|>
You are a professional psychotherapist with outstanding writing skills. Please revise the following psychotherapy note in a more integrated, professional style, adding pronouns ${pronounUseValue} while preserving all the information provided.
${noteStyleInstruction}
Make the note ${lengthValue}
Retain any medical necessity statements exactly as provided in the original note.
Preserve any statements made in quotation marks verbatim and in quotation marks.
Preserve any assessments of risk to self (or other) verbatim (that is, restate exactly what is in the original note). DO NOT mention anything about risk assessment unless it is in the input.
Include any biopsychosocial information when specified in the original note.
Please do not use the word "abuse" to refer to the problematic use of substances or to substance use difficulties of any kind.
Be careful to maintain the difference between "observed" versus "reported" affective/emotional states.
Please do not use the term "conversation" when referring to the discussion/interview/assessment during the session.
Importantly, ${pronounUseValue} ${pronounValue} as well as using the term ${terminologyValue}
${therapistPronounsValue} refer to the therapist in the first person ("I" or "me").
DO NOT mention anything about risk assessment that is not in the input.
Integrate all the following information and respond with only the revised note; no additional comments:
<|im_end|>
<|im_start|>user<|im_sep|>`;

		// Create the complete prompt object
		const newFilledPrompt = {
			messages: systemPrompt,
			useBulletPoints: formatValue === 'Use the form of bullet-points',
			temperature: promptOptions?.elaboration?.value,
			language: promptOptions?.language?.value,
			length: lengthValue === 'relatively short and concise.' ? 'relatively long and comprehensive.' : undefined,
		};
		// Set the global AI prompt
		setGlobalAIPrompt(newFilledPrompt);
	}, [
		promptOptions?.pronouns?.value,
		promptOptions?.pronounUse?.value,
		promptOptions?.therapistPronouns?.value,
		promptOptions?.noteStyle?.value,
		promptOptions?.terminology?.value,
		promptOptions?.formatoptions?.value,
		promptOptions?.language?.value,
		promptOptions?.length?.value,
		promptOptions?.elaboration?.value,
		multiLevelPreferences.clientTerminology,
		setGlobalAIPrompt,
	]);

	// Render the choices for a given parameter
	const renderChoices = useCallback(param => {
		const { label, values } = promptOptionsList[param];
		return (
			<Form.Group style={{ marginBottom: '1rem' }}>
				<h5>{label}</h5>
				{values.map(option => (
					<RadioInput
						key={option.key}
						value={option.text}
						label={option.key}
						currentValue={promptOptions[param]?.value}
						name={option.key}
						handleChange={() => handleChange(param, option.text)}
						checked={promptOptions[param]?.value === option.text}
					/>
				))}
			</Form.Group>
		);
	}, [promptOptions, handleChange]);

	const renderTemperatureSlider = useCallback(() => {
		return (
			<Form.Group style={{ marginBottom: '1rem' }}>
				<h5>Elaboration</h5>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<span style={{ minWidth: '7rem' }}>closer to the input</span>
					<Form.Control
						type="range"
						min="0.1"
						max="2.0"
						step="0.1"
						value={promptOptions.elaboration.value}
						onChange={(e) => handleChange('elaboration', parseFloat(e.target.value))}
						style={{ flex: 1, margin: '0 0.625rem' }}
					/>
					<span style={{ minWidth: '7rem' }}>more elaborated</span>
				</div>
				<div style={{ textAlign: 'center', marginTop: '0.3125rem' }}>
					Current value: {promptOptions.elaboration.value}
				</div>
			</Form.Group>
		);
	}, [promptOptions.elaboration.value, handleChange]);

	return (
		<PromptBuilderContainer>
			<Form>
				{renderChoices('noteStyle')}
				{renderChoices('terminology')}
				{renderChoices('pronouns')}
				{renderChoices('pronounUse')}
				{renderChoices('therapistPronouns')}
				{renderTemperatureSlider()}
				{renderChoices('formatoptions')}
				{renderChoices('length')}
				{renderChoices('language')}
			</Form>
		</PromptBuilderContainer>
	);
};

export default GlobalAiPromtBuilder;

// Styled component for the prompt builder container
const PromptBuilderContainer = styled.div`
	overflow-y: auto;
`;
