import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { axiosClient } from 'index';
import Cookies from 'js-cookie';
import LoginModal from 'layouts/login/LoginModal';

import CallToSubscribe from 'layouts/subscribe/CallToSubscribe';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Routes } from 'routes';

import { AuthContext } from '@note/contexts/AuthContext';
import { NoteProvider } from '@note/contexts/NoteContext';
import { PreferenceProvider } from '@note/contexts/PreferenceContext';
import TermsAndConditionsModal from '@note/modals/TermsAndConditionsModal';
import { getMyInfo, getSignature } from '@note/services/user';

import 'react-image-crop/dist/ReactCrop.css';
import 'react-toastify/dist/ReactToastify.css';

const prodApp = window.location.origin.includes('app2.notedesigner');
const queryClient = new QueryClient();

// Set up the Sentry error monitoring and tracing integration
Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	integrations: [
		new BrowserTracing({
			tracePropagationTargets: ['https://notedesigner.webisoft.org/', 'https://app2.notedesigner.com/', /^\//],
		}),
	],
	tracesSampleRate: 0,
});

const App = () => {
	const { user, setUser, setIsAdmin, setSignature, setSignatureImage, logoutFromModal, setSignatureTextObject } =
		useContext(AuthContext);
	const [shouldShowTCModal, setShouldShowTCModal] = useState(user ? (user.has_accepted_terms && !user.is_demo) : false);

	const [showLoginModal, setShowLoginModal] = useState(false);
	const [showCallToSubscribeModal, setShowCallToSubscribeModal] = useState(false);

	// Fetch the CSRF token from cookies to include in requests
	const csrfToken = Cookies.get('csrftoken');
	const hostname = window.location.origin;

	const isNotLogingPage = useCallback(
		url => {
			return url !== `${hostname}/login`;
		},
		[hostname]
	);

	Sentry.setUser({ email: user?.email });

	const openLoginModal = () => {
		setShowLoginModal(true);
	};

	const openCallToSubscribeModal = () => {
		setShowCallToSubscribeModal(true);
	};
	const closeCallToSubscribeModal = (allowClosing = false) => {
		setShowCallToSubscribeModal(false);
	};

	const closeLoginModal = (allowClosing = false) => {
		if (allowClosing || csrfToken) {
			setShowLoginModal(false);
		} else {
			toast('Please login to continue', { type: 'error' });
		}
	};

	// Initialize Facebook Pixel tracking with advanced matching for user email
	const setPixel = () => {
		const advancedMatching = { em: user.email };
		const options = {
			autoConfig: true,
			debug: false,
		};
		try {
			ReactPixel.init('666599828747909', advancedMatching, options);
			ReactPixel.pageView();
		} catch (error) {
			console.error('Error initializing Facebook Pixel:', error);
		}
	};

	const logoutModal = useCallback(async () => {
		if (window.confirm('Are you sure you want to log out?')) {
			window.onbeforeunload = null;
			await logoutFromModal();
			setShowLoginModal(false);
		}
	}, [setShowLoginModal, logoutFromModal]);

	// Effect to handle different custom events for error handling and triggering modals
	useEffect(() => {
		const badCredentialsError = event => {
			toast.error(`Email or password are incorrect`);
		};
		const handleUnauthorizedError = event => {
			isNotLogingPage(window.location.href)
				? openLoginModal()
				: toast.error(`${event.detail} Something went wrong! Please try again.`);
		};

		const handleUnsubscribedError = event => {
			isNotLogingPage(window.location.href)
				? openCallToSubscribeModal()
				: toast.error(`${event.detail} Something went wrong! Please try again.`);
		};

		const handleGeneralError = event => {
			toast.error(`${event.detail} Something went wrong! Please try again.`);
		};

		window.addEventListener('bad-credentials-error', badCredentialsError);
		window.addEventListener('unauthorized-error', handleUnauthorizedError);
		window.addEventListener('unsubscibed-error', handleUnsubscribedError);
		window.addEventListener('general-error', handleGeneralError);

		return () => {
			window.removeEventListener('unauthorized-error', handleUnauthorizedError);
			window.removeEventListener('unsubscibed-error', handleUnsubscribedError);
			window.removeEventListener('general-error', handleGeneralError);
		};
	}, [openLoginModal, isNotLogingPage]);

	// Effect to trigger login modal when user session is cleared from localStorage
	useEffect(() => {
		const handleStorageChange = e => {
			if (e.key === 'user' && e.newValue === null) {
				isNotLogingPage(window.location.href) && openLoginModal();
			}
		};
		window.addEventListener('storage', handleStorageChange);
		return () => {
			window.removeEventListener('storage', handleStorageChange);
		};
	}, [isNotLogingPage]);

	// Axios interceptor to automatically include CSRF token in requests
	useEffect(() => {
		axiosClient.interceptors.request.use(function (config) {
			config.headers['X-CSRFToken'] = Cookies.get('csrftoken');
			return config;
		});

		// Sentry integration with Axios to capture exceptions
		axiosClient.interceptors.response.use(
			response => response,
			error => {
				if (!error.response) {
					Sentry.captureException(error, {
						context: {
							request: {
								url: error.config.url,
								method: error.config.method,
								headers: error.config.headers,
								body: error.config.data,
							},
							response: {
								status: 'Network Error',
								statusText: 'Network Error',
								data: 'The request was made but no response was received',
							},
							environment: process.env.NODE_ENV,
							errorMessage: error.message,
							userAgent: navigator.userAgent,
						},
					});
				} else {
					Sentry.captureException(error, {
						context: {
							request: {
								url: error.config.url,
								method: error.config.method,
								headers: error.config.headers,
								body: error.config.data,
							},
							response: {
								status: error.response.status,
								statusText: error.response.statusText,
								data: error.response.data,
							},
							environment: process.env.NODE_ENV,
							errorMessage: error.message,
							userAgent: navigator.userAgent,
						},
					});
				}
				throw error;
			}
		);
	}, []);

	const loadData = useCallback(async () => {
		const [signature, userInfoResponse] = await Promise.all([getSignature(), getMyInfo()]);
		if (userInfoResponse?.data) {
			const [userInfo] = userInfoResponse?.data;
			setUser({ ...userInfo, id: userInfo.pk });
			user && Sentry.setUser({ email: user?.email });
			prodApp && user && setPixel();
			setIsAdmin(userInfo.is_admin);
			setSignature(signature.data.signature);
			setSignatureImage(signature.data.signature_image);
			setSignatureTextObject(signature.data.signature_json);
			setShouldShowTCModal(userInfo && !userInfo.has_accepted_terms && !userInfo.is_demo);
		}
	}, [setPixel, setIsAdmin, setSignature, setSignatureImage]);

	// Trigger data load if the CSRF token is available and the current page is not the login page
	useEffect(() => {
		if (csrfToken && isNotLogingPage(window.location.href)) {
			loadData();
		}
	}, [csrfToken, isNotLogingPage]);

	return (
		<QueryClientProvider client={queryClient}>
			<NoteProvider>
				<PreferenceProvider>
					<BrowserRouter>
						<Routes />
						<ToastContainer />
						{showLoginModal && window.location.pathname !== '/request-demo' && (
							<LoginModal show={showLoginModal} handleCloseLoginModal={closeLoginModal} modalLogout={logoutModal} />
						)}
						{showCallToSubscribeModal && (
							<CallToSubscribe show={showCallToSubscribeModal} handleCloseCallToSubscribe={closeCallToSubscribeModal} />
						)}
						{user && <TermsAndConditionsModal show={shouldShowTCModal} />}
					</BrowserRouter>
				</PreferenceProvider>
			</NoteProvider>
		</QueryClientProvider>
	);
};

export default Sentry.withProfiler(App);
